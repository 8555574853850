import React, { useCallback } from "react";
import styled, { css } from "styled-components";

import { HexSwitch } from "../../../hex-components";

import { BaseInputConfiguration } from "./BaseInputConfiguration";
import { InputConfigurationComponent } from "./InputConfigurationComponent";

const StyledSwitch = styled(HexSwitch)`
  margin-bottom: 0;
`;

const ICON_STYLES = css`
  top: -4px;
`;

export const InputBooleanConfiguration: InputConfigurationComponent<boolean> =
  React.memo(function InputBooleanConfiguration({
    description,
    disabled,
    error,
    info,
    label,
    labelOnRight = false,
    onChange,
    value,
  }) {
    const onChangeCallback = useCallback(
      (evt: React.FormEvent<HTMLInputElement>) => {
        onChange(evt.currentTarget.checked);
      },
      [onChange],
    );
    return labelOnRight ? (
      <BaseInputConfiguration
        disabled={disabled}
        error={error}
        iconStyles={ICON_STYLES}
        info={info}
      >
        <HexSwitch
          checked={Boolean(value)}
          disabled={disabled}
          label={label}
          onChange={onChangeCallback}
        />
      </BaseInputConfiguration>
    ) : (
      <BaseInputConfiguration
        disabled={disabled}
        error={error}
        iconStyles={ICON_STYLES}
        info={info}
        label={label}
      >
        <StyledSwitch
          checked={Boolean(value)}
          disabled={disabled}
          labelElement={description}
          onChange={onChangeCallback}
        />
      </BaseInputConfiguration>
    );
  });
